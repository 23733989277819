import { inject, Injectable, signal } from '@angular/core';
import { ApiService } from '@core/api/api.service';
import { catchError, tap } from 'rxjs';

import {
	DeviceAndroidDetail,
	DeviceAndroidUpdate,
	DeviceRestrictionUpdate,
} from '../devices/devices.types';
import { Group } from '../policy-details/policy-details.types';
import { UserService } from '../user/user.service';
import { NewDevicePayload } from './device-details.types';

@Injectable({
	providedIn: 'root',
})
export class DeviceDetailsService {
	apiService = inject(ApiService);
	userService = inject(UserService);

	isLoading = signal<boolean>(false);
	device = signal<DeviceAndroidDetail>({} as DeviceAndroidDetail);

	addDevice(device: NewDevicePayload) {
		return this.apiService.post<string>('/android/device', device);
	}

	deleteDevice(deviceId: string) {
		return this.apiService.delete(`/android/device/${deviceId}`);
	}

	getDevice(deviceId: string) {
		this.isLoading.set(true);

		return this.apiService.get<any>(`/android/device/${deviceId}`).pipe(
			tap((data) => {
				this.device.set(data);
				this.isLoading.set(false);
			}),
			catchError(() => {
				this.isLoading.set(false);
				throw new Error('Error getting device details');
			}),
		);
	}

	getGroupsByCustomer(customerId: string) {
		return this.apiService
			.get<Group[]>(`/android/groups/getbycustomer/${customerId}`)
			.pipe(catchError(() => []));
	}

	sendCommand(deviceId: string, command: number) {
		this.isLoading.set(true);

		return this.apiService
			.get(`/android/device/sendcommand/${deviceId}?command=${command}`)
			.pipe(tap(() => this.isLoading.set(false)));
	}

	updateDevice(deviceId: string, data: DeviceAndroidUpdate) {
		return this.apiService.put<void>(`/android/device/${deviceId}`, data);
	}

	updateDeviceRestriction(deviceId: string, data: DeviceRestrictionUpdate) {
		const user = this.userService.user;

		return this.apiService.put<void>(`/android/device/settingsvalue/${deviceId}`, data, {
			params: {
				access: user().selectedGroup,
			},
		});
	}

	removeTimer(deviceId: string) {
		return this.apiService.get(`/android/device/removetemporarypolicy/${deviceId}`);
	}
}
